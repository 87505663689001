import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/footer'
import Header from '../components/header'

export default function PricingPage() {
  return (
    <div>
        <Header/>
        <div className='bg-[#f8f8f8] mt-[70px] pt-[70px] flex flex-col gap-[65px]'>
            <div className='flex md:flex-row flex-col gap-[20px] justify-between md:gap-[0px] w-[85%] mx-auto'>
              <div className='md:w-[48%] border-[2px] pb-[50px] rounded-[8px] p-[60px] flex flex-col gap-[30px]'>
                <div className='flex flex-col gap-[10px]'>
                <h2 className='text-[#0044F1] md:text-[25px] text-[18px] font-[700]'>Starter</h2>
                <span className='leading-8'>Ideal for new product development and testing for startups. Obtain your API keys and begin testing right away.</span>
                </div>
                <span className='font-[800] text-[#000000]'>Pay as low as N30/API</span>
                <div className='flex flex-col gap-[20px]'>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Test in sandbox environment</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Unlimited test credentials</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Developer/Technical support</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Real-time Slack notifications</span>
                  </div>
                </div>
                <Link className='block w-full text-center bg-[#0165FE] py-[12px] text-white hover:text-white hover:opacity-70 rounded-[8px]' to='/'>Get Started</Link>
              </div>
              <div className='md:w-[48%] border-[2px] pb-[50px] rounded-[8px] p-[60px] flex flex-col gap-[30px]'>
                <div className='flex flex-col gap-[10px]'>
                <h2 className='text-[#0044F1] md:text-[25px] text-[18px] font-[700]'>Growth</h2>
                <span className='leading-8'>Increasing your growth phase and optimizing your product.</span>
                </div>
                <span className='font-[800] text-[#000000]'>Pay as low as N30/API</span>
                <div className='flex flex-col justify-between gap-[20px]'>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Everything in Starter plan</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Go live on production environment</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Volume discount (30% - 80%)</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Account management</span>
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <img className='h-[20px]' src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1691486893/tick_mvpa4b.png" alt="" />
                    <span className='font-bold'>Integration Support</span>
                  </div>
                </div>
                <Link className='block w-full text-center bg-[#0165FE] py-[12px] text-white hover:text-white hover:opacity-70 rounded-[8px]' to='/'>Get Started</Link>
              </div>
            </div>
            <div className='flex flex-col gap-[48px]'>
              {/* <div className='flex flex-col lg:flex-row lg:justify-between gap-[20px] lg:gap-[0px] w-[85%] mx-auto shadow-box rounded-[20px] px-[27px] py-[42px] items-center'>
                <div className='flex flex-col md:gap-[20px] gap-[10px] items-center text-center lg:items-start'>
                  <h2 className='text-[#0044F1] md:text-[33px] text-[25px] font-[700] text-center'>Find answers to common questions </h2>
                  <p>Find answers to common questions </p>
                </div>
                <Link className='lg:w-[20%] w-[50%] text-center bg-[#0165FE] py-[12px] rounded-[12px] text-white hover:text-white hover:opacity-70' to='/faq'>Check FAQs</Link>
              </div> */}
              <div className='flex flex-col lg:flex-row lg:justify-between gap-[20px] lg:gap-[0px] w-[85%] mx-auto shadow-box rounded-[20px] px-[27px] py-[42px] items-center '>
                <div className='flex flex-col md:gap-[20px] gap-[10px] items-center text-center lg:items-start'>
                  <h2 className='text-[#0044F1] md:text-[33px] text-[25px] font-[700] '>Join our developer community on Telegram</h2>
                  <p>Chat lives with developers on the official Telegram Channel.</p>
                </div>
                <a href='https://t.me/+OFUW6YutOewyNzk0' target='_blank' className='lg:w-[20%] w-[60%] text-center bg-[#0165FE] py-[12px] rounded-[12px] text-white hover:text-white hover:opacity-70' to='/slack'>Join our Telegram Channel</a>
              </div>
            </div>
            <div className='bg-[#0165FE] flex flex-col gap-[34px] pt-[37px] pb-[56px] text-white items-center'>
              <div className='w-[90%] mx-auto text-center max-w-[600px] leading-8'>
                <p>Explore our computer vision detection playground or create a PowerdbyAI account now and start verifying identity documents. You can also contact us to build custom identity APIs for your business. </p>
              </div>
              <Link className='py-[12px] px-[67px] rounded-[12px] text-[#0047FF] font-[500] hover:text-[#0047FF] hover:opacity-70 bg-white' to='/app/idntity/playground' reloadDocument> Get Started</Link>
            </div>
        </div>
        <Footer/>
        <style jsx='true'>
          {`
            .shadow-box{
              box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
            }
          `}
        </style>
    </div>
  )
}
