export default async function fetcher(url = "", content = {}) {
  try {
    const res = await fetch(url, content);
    const data = await res.json();

    if (!res.ok || !data.success) {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    return { failure: true, message: error.message };
  }
}
