import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import Firstsection from '../../components/marketingpages/Firstsection'
import Lastsection from '../../components/marketingpages/Lastsection'
import Secondsection from '../../components/marketingpages/Secondsection'
import Thirdsection from '../../components/marketingpages/Thirdsection'

export default function BiometricVerification() {
  return (
    <div>
    <Header/>
    <div className='bg-[#f8f8f8] mt-[70px] mb-[64px] pt-[70px] flex flex-col gap-[150px] w-[85%] mx-auto'>
        <Firstsection 
            tag='Biometric Verification' 
            mainHeader='Authenticate users with their facial biometrics'
            paragraph='Verify users with a selfie. PowerdbyAI checks that their facial biometrics matches a valid ID'
            getStartedLink='/'
            bookADemoLink='/'
            imgURL='https://res.cloudinary.com/analytics-intelligence/image/upload/v1677742425/Group_11770_hpobie.svg'
            className='top-[-10px] right-[60px]'
        />
        <Secondsection
            header1='Facial Biometric Onboarding'
            label1='Facilitate user onboarding with facial biometric verification.'
            icon1='https://res.cloudinary.com/analytics-intelligence/image/upload/v1677682315/face-id_1_gc7gsx.svg'
            header2='Effortless Identity Verification'
            label2='Streamline your verification process and reduce friction for users with PowerdbyAI.'
            icon2='https://res.cloudinary.com/analytics-intelligence/image/upload/v1677682308/verification_1_zidwby.svg'
            header3='Combatting Fraud with Robust Safeguards'
            label3='Prevent fraudulent registrations and user impersonation with effective safeguards.'
            icon3='https://res.cloudinary.com/analytics-intelligence/image/upload/v1677682302/personal-data_1_k9jrx9.svg'
        />
        <Thirdsection
            mainHeader='Be in compliance with Anti Money Laundering requirements '
            paragraph='Biometric verification with selfies allows your business spot fraudsters before they gain entry into your platform and impersonate an unsuspecting user'
            point1='Automate selfie logins'
            point2='Leverage biometric verification for multi-factor authentication '
            point3='Added layer of protection for your users '
            imgURL='https://res.cloudinary.com/analytics-intelligence/image/upload/v1677682297/Group_5_na0zrd.svg'
            className='top-[28px] w-[450px]'
        /> 
        <Lastsection/>
    </div>
    <Footer/>
</div>
  )
}
